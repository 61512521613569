











































import { Component, Vue, Prop } from 'vue-property-decorator';
import {LinkPreviewQuery} from "@/graphql/LinkPreview";
import AddBookmark from "@/components/bookmarks/AddBookmark.vue";
import {Bookmark} from "@/typescript/types";

@Component({
  components: {},
})
export default class UrlPreview extends Vue {
  @Prop({ required: true })
  url!: string;

  @Prop({ required: false })
  boardId!: string;

  title: string | null = null;
  description: string | null = null;
  image: string | null = null;
  favicon: string | null = null;
  host: string | null = null;

  get boardBookmarks() {
    return this.$store.state.board.bookmarks;
  }

  get isAddBookmarkOpen() {
    let data = this.boardBookmarks.find((bookmark: Bookmark) => bookmark.url == this.$props.url);
    if (data) {
      return false;
    }
    return true
  }

  embedLink() {
    this.$router.push({
      name: 'board-embed'});
  }

  getUrlPreviewData(url: string) {
    this.$apollo
      .query({
        query: LinkPreviewQuery,
        variables: {
          url: url
        }
      })
      .then((data: any) => {
        
        if (data.data) {
          this.title = data.data.getPreviewData.title;
          this.description = data.data.getPreviewData.description;
          this.image = data.data.getPreviewData.image;
          this.favicon = data.data.getPreviewData.favicon;
          this.host = data.data.getPreviewData.host;
          this.$emit('loaded', true);
        }
      });
  }

  openCreateBookmark(url: string){
    this.$buefy.modal.open({
      // eslint-disable-next-line no-undef
      component: AddBookmark,
      parent: this,
      props: {
        urldata: url,
        origin: 1,
        origin_id: this.boardId,
        inModal: true
      },
      width: '500px',
    })
  }

  mounted(){
    this.getUrlPreviewData(this.url);
  }
}
