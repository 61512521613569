import gql from 'graphql-tag';


export const LinkPreviewDetailedFragment = gql`
    fragment LinkPreviewDetailed on LinkPreview {
        title
        description
        image
        favicon
        url
        host
    }
`;

export const LinkPreviewQuery = gql`
    query LinkPreview($url: String!) {
        getPreviewData(url: $url) {
            ...LinkPreviewDetailed
        }
    }
    ${LinkPreviewDetailedFragment}
`;